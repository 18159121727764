.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: background-color 0.2s;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.4);
    /* Add a subtle shadow to the slider */
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s, background-color 0.2s;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
    /* Add a subtle shadow to the circle */
}

.slider.on:before {
    transform: translateX(16px);
}

.slider.on {
    background-color: blue;
}

.switch input:checked+.slider:before {
    background-color: white;
}

.switch input:checked+.slider {
    background-color: blue;
}